<template>
  <CRow>
    <Modal :modal="alertModal"></Modal>

    <CModal
      color="danger"
      :title="`Excluir ${cardTitle}`"
      :show.sync="modal"
      :centered="true"
    >
      Após excluir não será possível reverter a ação
      <template #footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="() => (modal = false)"
          :disabled="removing"
        >
          Cancelar
        </button>
        <button
          type="button"
          class="btn btn-danger"
          @click="remove"
          :disabled="removing"
        >
          {{ removing ? 'Excluindo...' : 'Excluir' }}
        </button>
      </template>
    </CModal>

    <CCol lg="12">
      <div class="d-flex align-items-center justify-content-end mb-3">
        <a href="javascript:void(0)" @click="$router.go(-1)">
          <CIcon name="cil-arrow-left" class="mr-2" />Voltar
        </a>
      </div>
      <FormCardTitle v-model="item" :title="cardTitle">
        <template #append>
          <CButton
            @click="() => (modal = true)"
            color="danger"
            class="float-right"
          >
            <CIcon name="cil-trash" class="mr-1" />
            <span>Excluir Assinatura</span>
          </CButton>
        </template>
      </FormCardTitle>

      <FormCardItem title="Dados informativos">
        <CCol col="12" md="4">
          <p>
            <b>Nome </b><br />
            {{ item.name }}
          </p>
        </CCol>
        <CCol col="12" md="4">
          <p>
            <b>Cargo </b><br />
            {{ item.responsibility }}
          </p>
        </CCol>
        <CCol col="12" md="4">
          <p>
            <b>Celular Corporativo </b><br />
            {{ item.corporate_cell_phone }}
          </p>
        </CCol>
        <CCol col="12" md="4">
          <p>
            <b>Telefone da unidade </b><br />
            {{ item.unit_phone }}
          </p>
        </CCol>
        <CCol col="12" md="4">
          <p>
            <b>Endereço da unidade </b><br />
            {{ item.unit_address }}
          </p>
        </CCol>
        <CCol col="12" md="4">
          <p>
            <b>Número da unidade</b><br />
            {{ item.unit_number }}
          </p>
        </CCol>
        <CCol col="12" md="4">
          <p>
            <b>Cidade/Estado da cidade </b><br />
            {{ item.city[0].name }}
          </p>
        </CCol>
      </FormCardItem>

      <CRow>
        <CCol sm="12">
          <CardLog :log="log" />
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import FormCardTitle from '@/components/ui/Form/FormCardTitle'
import FormCardItem from '@/components/ui/Form/FormCardItem'
import CardLog from '@/components/ui/CardLog'
import Modal from '@/components/ui/Modal'

import Service from '@/services/signature.service'
import { sleep } from '@/utils'
import moment from 'moment'

const defaultItem = () => ({
  name: '',
  email: '',
  responsibility: '',
  corporate_cell_phone: '',
  unit_phone: '',
  unit_address: '',
  unit_number: '',
  city: []
})

export default {
  metaInfo: {
    title: 'Assinaturas',
    titleTemplate: 'Manager - Rodonaves Iveco - %s'
  },

  components: { Modal, FormCardTitle, FormCardItem, CardLog },

  data: () => ({
    item: defaultItem(),
    service: null,
    log: {},
    restrictedData: false,
    modal: false,
    removing: false,
    alertModal: {
      show: false,
      title: '',
      message: '',
      color: ''
    }
  }),

  async created() {
    this.setItem()
  },

  computed: {
    cardTitle() {
      const id = this.item.id ? ` #${this.item.id}` : ''
      return `Assinatura - ${this.item.name}${id}`
    }
  },

  methods: {
    async setItem() {
      const { id } = this.$route.params
      if (id) {
        const data = await Service.show(id)

        if (data) {
          this.item = { ...this.item, ...data }

          this.log = {
            created_by: data.name,
            created_at: moment(data.created_at).format('DD/MM/YYYY HH:mm'),
            updated_by: data.name,
            updated_at: moment(data.change_of_data).format('DD/MM/YYYY HH:mm')
          }
        }
      }
    },
    async remove() {
      const { id } = this.$route.params
      this.removing = true
      const redirect = `/signature`
      let modalData = {
        show: true,
        title: 'Sucesso',
        message: 'Assinatura excluída com sucesso.',
        color: 'success',
        redirect: ''
      }

      try {
        await Service.delete(id)
        modalData = { ...modalData, redirect }
      } catch {
        modalData = {
          ...modalData,
          title: 'Erro',
          message: 'Ocorreu um erro ao excluir lead.',
          color: 'danger',
          redirect: ''
        }
      } finally {
        this.removing = false
        this.modal = false

        await sleep(200)
        this.alertModal = modalData
        await sleep(3000)

        modalData.redirect
          ? this.$router.push({ path: redirect })
          : (this.alertModal.show = false)
      }
    }
  }
}
</script>
